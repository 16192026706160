<template>
  <div class="screen">
    <div class="screen__description">
      <div class="screen__header">
        <h2>Get notifications when<br> they arrive at or leave <br>a certain place </h2>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './styles.scss';
</style>